import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, Inject, Injector, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { IMAGE_LOADER, ImageLoaderConfig, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClient, HttpClientModule, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, provideClientHydration} from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { NotfoundModule } from './errors/notfound.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { OfferService } from './offer/offer.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Page, Setup } from './shared/navigation/pages.model';
import { NavigationService } from './shared/navigation/navigation.service';
import { UserService } from './user/user.service';
import { CartService } from './shared/cart/cart.service';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { TimerComponent } from './shared/helpers/timer.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { UpdateService } from './update.service';

export function jwtOptionsFactory(platformId) {
  return {
    tokenGetter: () => {
      let token = null;
      if (isPlatformBrowser(platformId)) {
        token = localStorage.getItem('access_token');
      }
      return token;
    },
    whitelistedDomains: ['localhost:4200', environment.api]
  };
};

export class JwtHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) private localeId: string, @Inject(DEFAULT_CURRENCY_CODE) private currency: string) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let clone: HttpRequest<any>;
      let headers = {
        Accept: `application/json`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Content-Language': this.localeId,
        'Content-Currency': this.currency
      };
      let token = null;
      if (isPlatformBrowser(this.platformId)) {
        token = localStorage.getItem('user_token');
        let currency = 'PLN';
        let user_currency = localStorage.getItem('user_currency');
        if (user_currency) {
          currency = user_currency;
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        } 
        // if (currency) {
          // this.currency = currency;
          // headers['Content-Currency'] = currency;
        // }
      }
      clone = request.clone({
        setHeaders: headers
      });
      return next.handle(clone);
  }
}
function initializeCurrency(injector: Injector) {
  
  let currency = 'PLN';
  let user_currency = null;
  // const platformId = injector.get(PLATFORM_ID);
  // const isBrowser = isPlatformBrowser(platformId);
  let user: any;
  // if (isBrowser) {
  //   user = transferStateService.get('USER_DATA_KEY');
  //   user_currency = localStorage.getItem('user_currency');
  // } else {
  //   user = injector.get(USER_DATA) || null;
  //   transferStateService.set('USER_DATA_KEY', user);
  // }

  if (user) {
    currency = user.currency;
  }
  if(user_currency) {
    currency = user_currency;
  }

  return currency;
}

function initializeLocale(injector: Injector) {
  
  let localeId = 'pl';

  // const platformId = injector.get(PLATFORM_ID);
  // const isBrowser = isPlatformBrowser(platformId);
  // let user: any;
  // if (isBrowser) {
    // if (navigator) {
    //   if (navigator.language) {
    //     localeId = navigator.language.slice(0,2);
    //     console.log(localeId, 'APPMODULE NAVIGATOR');
    //   }
    // } 
  //   user = transferStateService.get('USER_DATA_KEY');
  // } else {
  //   // console.log(localeId, 'APPMODULE NAVIGATOR ELSE');
  //   user = injector.get(USER_DATA) || null;
  //   transferStateService.set('USER_DATA_KEY', user);
  // }

  // if (user) {
  //   // console.log(user, 'APPMODULE USER');
  //   localeId = user.locale;
  // }

  // localeId = 'en';
  // console.log('initializeLocale', localeId);
  
  import(
    /* webpackInclude: /(pl|de|en|fr|es|it)\.mjs$/ */
    `@/../@angular/common/locales/${localeId}.mjs`
    ).then(localModule => {
      registerLocaleData(localModule.default);
  });
  

  return localeId;
}

const withChildren = {'ArticleDetailsModule': ['/:path'], 'OfferDetailsModule': ['/:path', '/:path/:variant', '/:path/:variant/:configuration']};

function clearStorage() {
  localStorage.removeItem('user_token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_role');
  localStorage.removeItem('user_scopes');
  localStorage.removeItem('user_state');
  localStorage.removeItem('user_currency');
}

function initializeAppFactory(httpClient: HttpClient, navigationService: NavigationService, router: Router, jwtHelperService: JwtHelperService, localeId: string): () => Observable<any> {
  return () => httpClient.get<Setup>(environment.api + '/settings/'+localeId)
    .pipe(
      tap(setup => { 
      const navigation: Page[] = [];
      const navigationFooter: Page[] = [];
      const navigationInvisible: Page[] = [];
      const config = router.config;

      if (setup.settings) {
        config[0].data.title = setup.settings.seo_title;
        config[0].data.description = setup.settings.seo_description;
        config[0].data.keywords = setup.settings.seo_keywords;
        config[1].data.title = setup.settings.seo_title;
        config[1].data.description = setup.settings.seo_description;
        config[1].data.keywords = setup.settings.seo_keywords;
        config[2].data.title = setup.settings.seo_title;
        config[2].data.description = setup.settings.seo_description;
        config[2].data.keywords = setup.settings.seo_keywords;
        navigationService.ipAddress = setup.ip;
      }

      if (isPlatformBrowser(this.platformId)) {
        const token = localStorage.getItem('user_token');
        if (token) {
          if (jwtHelperService.isTokenExpired(token)) {
            clearStorage();
          }
        } else {
          clearStorage();
        }
      }

      recursiveLoop(config, setup.menu, navigation, navigationFooter, navigationInvisible);

      router.resetConfig(config);
      router.initialNavigation();

      navigationService.navigation.next({
        menu: navigation,
        footer: navigationFooter,
        invisible: navigationInvisible,
        settings: setup.settings,
        discounts: setup.discounts,
        payments: setup.payments.items || [],
        carriers: setup.carriers.items || []
      });

      })
  );

}; 

function prepareAddRoute(config: any, page: any) {
  const stackedRouteExist = config.find(x => x.path === page.path);
  if (!stackedRouteExist && page.path != undefined && page.path.length !== 0) {
    let moduleParts = [];
    if (page.module != null) {
      moduleParts = page.module.split('#');
    }
    if (moduleParts[0] != 'container') {

      let path = page.path;
      if (withChildren[moduleParts[1]]) { 
        withChildren[moduleParts[1]].forEach(childpath => {
          path = page.path + childpath;
          pushPage(config, page, path, moduleParts);  
        });
                
      } else {     
        pushPage(config, page, path, moduleParts);  
      }
    }
  }
}

function pushPage(config, page, path, module) {

  const r = config.find(c => c.path == (page.template ? page.template : module[1]));
  if (r) {
    const  newpage = {...r};
    newpage.path = path;
    newpage.data = {
        id: page.id,
        module: page.template ? page.template : module[1],
        level: page.level,
        parent: page.parent_id,
        name: page.name,
        path: page.path,
        path_id: page.path_id,
        title: (page.title ? page.title : page.name),
        content: page.content,
        description: page.description,
        keywords: page.keywords,
        extra_field_1: page.extra_field_1,
        extra_field_2: page.extra_field_2,
        extra_field_3: page.extra_field_3,
        extra_field_4: page.extra_field_4,
        extra_field_5: page.extra_field_5,
        template: page.template,
        hideBredcrumbs: (page.template ? true : false),
        search_params: page.search_params,
        photos_directory: page.photos_directory,
        canonical: environment.base + '/' + page.path
      };

    config.unshift(newpage);
  }
}

function recursiveLoop(config: any[], data: any[], navigation, navigationFooter, navigationInvisible) {


  var r = data.forEach((page: Page) => {
    //router part
    prepareAddRoute(config, page);
    //navigation part

    let children = [];
    let footer_children = [];

    if (+page.visible > 0 && page.visible_in.includes('main')) {
      if (page.children) {
        children = recursiveChildren(config, page.children, navigation, navigationFooter, navigationInvisible, './offer/offer.module#DummyModule', '!==', 'main');
      }
      const npage = { ...page };
      npage.children = [];
      if (children.length) npage.children = children;
      navigation.push(npage);
    }

    if (+page.visible > 0 && page.visible_in.includes('footer')) {
      if (page.children) {
        footer_children = recursiveChildren(config, page.children, navigation, navigationFooter, navigationInvisible, './offer/offer.module#DummyModule', '!==', 'footer');
      }
      const npage = { ...page };
      npage.children = [];
      if (footer_children.length) npage.children = footer_children;
      navigationFooter.push(npage);
    }

    if (+page.visible > 0) {
      const npage = { ...page };
      navigationInvisible.push(npage);
    }
  })

}

function recursiveChildren(config: any[], data: any[], navigation, navigationFooter, navigationInvisible, type: string, modifier: string, inside: string) {

  const children = [];
  var r = data.forEach((page: Page) => {

    prepareAddRoute(config, page);

    if (modifier === '===') {
      if (page.module === type && +page.visible > 0 && page.visible_in.includes(inside)) {
        if (page.children) {
          if (page.children.length) {
          page.children = recursiveChildren(config, page.children, navigation, navigationFooter, navigationInvisible, type, '===', inside);
          } 
        }
        children.push(page);
      }
    } else {
      if (page.module !== type && +page.visible > 0 && page.visible_in.includes(inside)) {
        if (page.children) {
          page.children = recursiveChildren(config, page.children, navigation, navigationFooter, navigationInvisible, type, '!==', inside);
        }
        // if (page.id == 21) {
        //   children.push(...page.children);
        // } else {
          children.push(page);
        // }
      }
    }
  })
  return children;
};

@NgModule({
  imports: [
    BrowserModule,
    TransferHttpCacheModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotfoundModule, 
    SharedModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [PLATFORM_ID]
      }
    }),
    LiveChatWidgetModule,
    TimerComponent
  ],
  declarations: [AppComponent],
  providers: [OfferService, UserService, CartService,
  {provide: LOCALE_ID, useFactory: initializeLocale, deps: [Injector]},
  {provide: DEFAULT_CURRENCY_CODE, useFactory: initializeCurrency, deps: [Injector]},
  {provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [HttpClient, NavigationService, Router, JwtHelperService, LOCALE_ID], multi: true}, //
  {provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, deps: [PLATFORM_ID, LOCALE_ID, DEFAULT_CURRENCY_CODE], multi: true },
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic', appearance: 'outline'}},
  {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue:{disabled: true}},
  {provide: IMAGE_LOADER,
    useValue: (config: ImageLoaderConfig) => {
      if (config.src.slice(-3) != 'svg') {
        config.src = config.src.slice(0, -3)+'webp';
      }
      if (config.loaderParams?.module == 'assets') {
        return 'assets/'+config.src;
      }
      let url = environment.cdn;
      if (config.loaderParams?.module) {
        url += '/'+config.loaderParams?.module;
      }
      if (config.loaderParams?.dir) {
        url += '/'+config.loaderParams.dir;
      }
 
        url += '/'+config.src;

      return url;

      // let url = environment.api+`/media/${config.src.replace('.', '/')}?`;
      // let queryParams = [];
      // if (config.loaderParams?.module) {
      //   queryParams.push('module='+config.loaderParams?.module);
      // }
      // if (config.loaderParams?.dir) {
      //   queryParams.push('dir='+config.loaderParams?.dir);
      // }
      // if (config.width) {
      //   queryParams.push(`w=${config.width}`);
      // }
      // return url + queryParams.join('&');
    }
  },
  provideClientHydration(),
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
